import { DiscountType } from "src/app/core/enums/discount-type.enum";
import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class CashflowTrailorsReportItemModel {
    public id: number;
    public rowNumber: number;
    public orderStatus: string;
    public cashflowDiscounted: number;
    public cashflowTrailed: number;
    public repName: string;
    public legacyRepName: string;
    public clientName: string
    public clientId: number;
    public province: string;
    public localCurrency: string;
    public cashflow: number;
    public cashflowInCad: number;
    public discountType: DiscountType;
    public isFirstOrder: boolean;
    public exchangeToCad: number;
    public dateOfOrder: Date;
    public dateOfCashflow: Date;
    public dateOfCashflowRulesExpiry: Date;
    public returnedCashflow: number;
    public returnedCashflowInCad: number;
    public cashflowRulesSum: number;
    public cashflowRulesSumInCad: number;
    public office: string;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfOrder: DateUtil.fromString,
            dateOfCashflow: DateUtil.fromString,
            dateOfCashflowRulesExpiry: DateUtil.fromString,
        });
    }
}
