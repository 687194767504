import { ExpenseType } from './../../../enums/expense-type.enum';
import { ItemStatus } from "src/app/core/enums/item-status.enum";
import { ObjectUtil } from 'src/app/core/utility/object.util';
import { DateUtil } from 'src/app/core/utility/date.util';

export class ExpensesReportItemModel {
    public id: number;
    public repName: string;
    public repId: number;
    public expenseType: ExpenseType;
    public provinceId: number;
    public province: string;
    public city: string;
    public currencyId: number;
    public currency: string;
    public categoryId: number;
    public category: string;
    public cadRate: number;
    public amount: number;
    public amountInCad: number;
    public description: string;
    public expenseDate?: Date;
    public receiptLink: string;
    public dateUploaded?: Date;
    public dateApproved?: Date;
    public status: ItemStatus;
    public approver: string;
    public rowNumber: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            expenseDate: DateUtil.fromString,
            dateUploaded: DateUtil.fromString,
            dateApproved: DateUtil.fromString,
        });
    }
}
