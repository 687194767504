import { ObjectUtil } from "../../utility/object.util";

export class BookingBuddyTableModel {
    public rep: string;
    public count: number;
    public revenueGenerated: number;
    public bbClosingPercent: number;
    public salesGenerated: number;
    public newSalesGenerated: number;
    public passedMeetings: number;
    public bbClientsCount: number;
    public farQB: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
